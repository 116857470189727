import { lazy } from 'react';

export type RoutesProps = {
  path: string;
  component: React.FC;
};

const routes: RoutesProps[] = [
  { path: '/', component: lazy(() => import('./pages/Index')) },
  { path: '/about', component: lazy(() => import('./pages/About')) },
  { path: '/services', component: lazy(() => import('./pages/Services/Index')) },
  { path: '/contact', component: lazy(() => import('./pages/Contact')) },
  { path: '/terms-and-conditions', component: lazy(() => import('./pages/Policies/TermsConditions')) },
  { path: '/privacy-policy', component: lazy(() => import('./pages/Policies/PrivacyPolicy')) },
];

export default routes;
