import React from "react";
import { Link } from "react-router-dom";

interface ButtonProps {
  text: string;
  type: string;
  to?: string;
  onClick?: (e: any) => void;
}

const Button = (props: ButtonProps) => {
  switch (props.type) {
    case "link":
      return (
        <Link to={props.to || ""} className="mjpls-button">
          {props.text}
          <div className="mjpls-button__line"></div>
        </Link>
      );

    case "submit":
      return (
        <button className="mjpls-button" type="submit" onClick={(e) => (props.onClick ? props.onClick(e) : {})}>
          {props.text}
          <div className="mjpls-button__line"></div>
        </button>
      );

    default:
      return (
        <button className="mjpls-button" type="button" onClick={(e) => (props.onClick ? props.onClick(e) : {})}>
          {props.text}
          <div className="mjpls-button__line"></div>
        </button>
      );
  }
};

export default Button;
