import React from 'react';
import Button from '../components/Button/Button';

const PageNotFound = () => {
  return (
    <div className='mjpls-404-error'>
      <h1>404</h1>
      <h2>Page Not Found</h2>
      <p>The resource requested could not be located</p>
      <Button
        {...{
          text: 'Back To Home',
          to: '/',
          type: 'link',
        }}
      />
    </div>
  );
};

export default PageNotFound;
