import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Components
import Loading from './components/Loading/Loading';

// Routes
import routes from './routes';
import PageNotFound from './pages/PageNotFound';

// Types
import { RoutesProps } from './routes';

const App = () => {
  useEffect(() => {
    // If path is index.html or /, redirect to the home page
    if (window.location.pathname === '/index.html') {
      window.location.href = `${window.location.origin}`;
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {routes.map((page: RoutesProps, index: number) => (
            <Route
              key={index}
              path={page.path}
              element={
                <React.Suspense fallback={<Loading />}>
                  <page.component />
                </React.Suspense>
              }
            />
          ))}

          {/* Change the path to / */}
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
